import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import styles from './banner.module.scss';

export const Banner = ({ data }) => {
  return (
    <>
      <div className="hide-md">
        <div
          className={
            data.imgDesktop.fluid.sizes.includes('600') ? styles.bannerSmall : styles.bannerImage
          }
        >
          <Img fluid={data.imgDesktop.fluid} alt="img" />
        </div>
      </div>
      <div className="hide-md-xl">
        <div className={styles.bannerImage}>
          <Img fluid={data.imgMobile.fluid} alt="img" />
        </div>
      </div>
    </>
  );
};
