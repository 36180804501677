import './HotelTab.scss';

import { MediaMatcher, MediaServerRender } from 'react-media-match';

import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Fenrir } from '../../Fenrir/Fenrir';
import { Freya } from '../../Freya/Freya';
import H2 from '../../Titles/TitleH2/TitleH2';
import H3 from '../../Titles/TitleH3/TitleH3';
import Hellheim from '../../Hellheim/Hellheim';
import Loki from '../../Loki/lokipane';
import { Odin } from '../../Odinv2/Odin';
import PawPanel from '../../PawPanel/PawPanel';
import Buttons from '../../Buttons/Buttons';
import Bragi from '../../Bragi/bragi';
import Thor from '../../Thor/Thor';
import Tyr from '../../Tyr/Tyr';
import Ymir from '../../Ymir/Ymir';
import Gerd from '../../Gerd/Gerd';
import Pawlink from '../../PawLink/PawLink';
import { Asgard } from '../../Asgard/Asgard';
import tt from '../../../helpers/translation';
import BannerImgTextButton from '../../BannerImgTextButton/BannerImgTextButton';
import { Banner } from '../../Banner/Banner';

function TabContainer(props) {
  const { children } = props; //eslint-disable-line

  return (
    <Typography component="div" style={{ fontFamily: 'Lato' }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = () => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'transparent',
    border: 'none',
    fontFamily: 'Lato',
  },

  default_tabStyle: {
    backgroundColor: '#FFFFFF',
    opacity: 0.8,
    height: '50px',
    width: 'auto',
    padding: '5px',
    marginRight: '3px',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '22px',
    textAlign: 'center',
    boxShadow: '0 2px 4px 0 rgb(0,0,0.5);',
    fontFamily: 'Lato',
  },

  active_tabStyle: {
    backgroundColor: '#FFFFFF',
    opacity: 1,
    height: '60px',
    width: 'auto',
    padding: '5px',
    borderRadius: '0 0 10px 10px',
    marginRight: '3px',
    fontWeight: '900',
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    color: '#1396D1',
    boxShadow: '0 2px 4px 0 rgb(0,0,0.5);',
    fontFamily: 'Lato',
  },
  indicator: {
    backgroundColor: 'transparent',
  },
  Wrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
    width: '100%',
    fontFamily: 'Lato',
  },
  labelContainer: {
    marginTop: '10px',
    fontFamily: 'Lato',
  },
  active_labelContainer: {
    marginTop: '20px',
    fontFamily: 'Lato',
  },
});

class HotelsTab extends React.Component {
  state = {
    value: null,
    openmenu1: false,
    openmenu2: false,
    openmenu3: false,
    activeTab: null,
  };

  handleClick = (key) => (e) => {
    const obj = {};
    this.state[key] = !this.state[key];
    this.setState(obj);
  };

  handleChangeTab = (event, value) => {
    console.log(value);
    this.setState({ value });
    this.props.onChangeData(value);
  };

  handleChange = (value) => {
    this.setState({ value });
    this.props.onChangeData(value);
  };

  render() {
    const {
      state: { openmenu1 },
      props: { data, classes, currentHotel, locale },
    } = this;
    const { value } = this.state;

    if (!value) {
      this.setState({ value: data.type });
      switch (data.type) {
        case 'INFO':
          this.setState({ activeTab: tt('INFORMACIÓN GENERAL', this.props.locale) });
          break;

        case 'EXCLUSIVE':
          this.setState({ activeTab: tt('BENEFICIOS EXCLUSIVOS', this.props.locale) });
          break;

        case 'ROOMS':
          this.setState({ activeTab: tt('HABITACIONES', this.props.locale) });
          break;

        case 'SERVICES':
          this.setState({ activeTab: tt('SERVICIOS', this.props.locale) });
          break;

        case 'RESTAURANT':
          this.setState({ activeTab: tt('RESTAURANTES', this.props.locale) });
          break;

        case 'GASTRO':
          this.setState({ activeTab: tt('EXPERIENCIA GASTRONÓMICA', this.props.locale) });
          break;

        case 'ARRIVE':
          this.setState({ activeTab: tt('CÓMO LLEGAR', this.props.locale) });
          break;

        default:
          this.setState({ activeTab: tt('INFO', this.props.locale) });
          break;
      }
    }

    const isMobileTrue = (
      <div className={`tab-menu ${data.type === 'INFO' ? 'bggrey' : ''}`}>
        <ListItem button className="firstlevelpasesmenu" onClick={this.handleClick('openmenu1')}>
          <ListItemText
            className="firstlevelpasesmenu-text"
            inset
            primary={`${this.state.activeTab}`}
          />
          {openmenu1 ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openmenu1} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              className="secondpaseslevelmenu"
              value="INFO"
              onClick={() => this.handleChange('INFO')}
            >
              <ListItemText
                className="secondpaseslevelmenu-text"
                inset
                primary={tt('INFORMACIÓN GENERAL', this.props.locale)}
              />
            </ListItem>

            <ListItem
              button
              className="secondpaseslevelmenu"
              value="EXCLUSIVE"
              onClick={() => this.handleChange('EXCLUSIVE')}
            >
              <ListItemText
                className="secondpaseslevelmenu-text"
                inset
                primary={tt('BENEFICIOS EXCLUSIVOS', this.props.locale)}
              />
            </ListItem>

            <ListItem
              button
              className="secondpaseslevelmenu"
              value="ROOMS"
              onClick={() => this.handleChange('ROOMS')}
            >
              <ListItemText
                className="secondpaseslevelmenu-text"
                inset
                primary={tt('HABITACIONES', this.props.locale)}
              />
            </ListItem>

            <ListItem
              button
              className="secondpaseslevelmenu"
              value="SERVICES"
              onClick={() => this.handleChange('SERVICES')}
            >
              <ListItemText
                className="secondpaseslevelmenu-text"
                inset
                primary={tt('SERVICIOS', this.props.locale)}
              />
            </ListItem>

            <ListItem
              button
              className="secondpaseslevelmenu"
              value="RESTAURANT"
              onClick={() => this.handleChange('RESTAURANT')}
            >
              <ListItemText
                className="secondpaseslevelmenu-text"
                inset
                primary={tt('RESTAURANTES', this.props.locale)}
              />
            </ListItem>

            {currentHotel === 'colorado-creek' ||
            currentHotel === 'gold-river' ||
            currentHotel === 'port-aventura' ? (
              <ListItem
                button
                className="secondpaseslevelmenu"
                value="GASTRO"
                onClick={() => this.handleChange('GASTRO')}
              >
                <ListItemText
                  className="secondpaseslevelmenu-text"
                  inset
                  primary={tt('EXPERIENCIA GASTRONÓMICA', this.props.locale)}
                />
              </ListItem>
            ) : null}

            <ListItem
              button
              className="secondpaseslevelmenu"
              value="ARRIVE"
              onClick={() => this.handleChange('ARRIVE')}
            >
              <ListItemText
                className="secondpaseslevelmenu-text"
                inset
                primary={tt('CÓMO LLEGAR', this.props.locale)}
              />
            </ListItem>
          </List>
        </Collapse>
      </div>
    );

    const isBrowserTrue = (
      <Tabs
        value={value}
        onChange={this.handleChangeTab}
        centered
        variant="fullWidth"
        className={data.type === 'INFO' ? 'tab-head bggrey with-anchor' : 'tab-head with-anchor'}
        classes={{ indicator: classes.indicator }}
      >
        <Tab
          label={tt('INFORMACIÓN GENERAL', locale)}
          value="INFO"
          className={data.type === 'INFO' ? classes.active_tabStyle : classes.default_tabStyle}
          classes={{
            labelContainer:
              data.type === 'INFO' ? classes.active_labelContainer : classes.labelContainer,
            wrapper: classes.Wrapper,
          }}
        />
        <Tab
          label={tt('BENEFICIOS EXCLUSIVOS', locale)}
          value="EXCLUSIVE"
          className={
            data.type === 'EXCLUSIVE'
              ? `${classes.active_tabStyle} EXCLUSIVE`
              : `${classes.default_tabStyle} EXCLUSIVE`
          } //eslint-disable-line
          classes={{
            labelContainer:
              data.type === 'EXCLUSIVE' ? classes.active_labelContainer : classes.labelContainer,
            wrapper: classes.Wrapper,
          }}
        />
        <Tab
          label={tt('HABITACIONES', locale)}
          value="ROOMS"
          className={
            data.type === 'ROOMS'
              ? `${classes.active_tabStyle} ROOMS`
              : `${classes.default_tabStyle} ROOMS`
          } //eslint-disable-line
          classes={{
            labelContainer:
              data.type === 'ROOMS' ? classes.active_labelContainer : classes.labelContainer,
            wrapper: classes.Wrapper,
          }}
        />
        <Tab
          label={tt('SERVICIOS', locale)}
          value="SERVICES"
          className={
            data.type === 'SERVICES'
              ? `${classes.active_tabStyle} SERVICES`
              : `${classes.default_tabStyle} SERVICES`
          } //eslint-disable-line
          classes={{
            labelContainer:
              data.type === 'SERVICES' ? classes.active_labelContainer : classes.labelContainer,
            wrapper: classes.Wrapper,
          }}
        />

        <Tab
          label={tt('RESTAURANTES', locale)}
          value="RESTAURANT"
          className={
            data.type === 'RESTAURANT'
              ? `${classes.active_tabStyle} RESTAURANT`
              : `${classes.default_tabStyle} RESTAURANT`
          } //eslint-disable-line
          classes={{
            labelContainer:
              data.type === 'RESTAURANT' ? classes.active_labelContainer : classes.labelContainer,
            wrapper: classes.Wrapper,
          }}
        />
        {currentHotel === 'colorado-creek' ||
        currentHotel === 'gold-river' ||
        currentHotel === 'port-aventura' ? (
          <Tab
            label={tt('EXPERIENCIA GASTRONÓMICA', locale)}
            value="GASTRO"
            className={
              data.type === 'GASTRO'
                ? `${classes.active_tabStyle} GASTRO`
                : `${classes.default_tabStyle} GASTRO`
            } //eslint-disable-line
            classes={{
              labelContainer:
                data.type === 'GASTRO' ? classes.active_labelContainer : classes.labelContainer,
              wrapper: classes.Wrapper,
            }}
          />
        ) : null}
        <Tab
          label={tt('CÓMO LLEGAR', locale)}
          value="ARRIVE"
          className={
            data.type === 'ARRIVE'
              ? `${classes.active_tabStyle} ARRIVE`
              : `${classes.default_tabStyle} ARRIVE`
          } //eslint-disable-line
          classes={{
            labelContainer:
              data.type === 'ARRIVE' ? classes.active_labelContainer : classes.labelContainer,
            wrapper: classes.Wrapper,
          }}
        />
      </Tabs>
    );

    const preparePromotion = (data) => {
      const obj = JSON.parse(JSON.stringify(data));
      obj.cards.forEach((e) => {
        const link = e.button2.cta;
        e.button2.cta = link;
      });
      return obj;
    };

    const prepareRoom = (data) => {
      const obj = JSON.parse(JSON.stringify(data));
      obj.cards.forEach((e) => {
        const link = e.buttons.cta2;
        if (link) {
          e.buttons.cta2 = link;
        }
      });
      return obj;
    };

    const isDesktop = (
      <>
        <Tyr data={data.tyrData && data.tyrData} />
        {this.props.pageContext.hotel_name === 'mansion-de-lucy' && data.textContentInfo && (
          <div className="special-text">
            <h2>{data.textContentInfo.edges[0].node.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: data.textContentInfo.edges[0].node.summary }} />
            <div
              dangerouslySetInnerHTML={{ __html: data.textContentInfo.edges[0].node.description }}
            />
          </div>
        )}
        {data.lokiData && data.lokiData.video && data.lokiData.video.length ? (
          <Bragi url={data.lokiData.video[0].node.video} />
        ) : null}
      </>
    );

    const isMobile = (
      <>
        <Tyr data={data.tyrData && preparePromotion(data.tyrData)} />
        {this.props.pageContext.hotel_name === 'mansion-de-lucy' && data.textContentInfo && (
          <div className="special-text-mobile">
            <h2>{data.textContentInfo.edges[0].node.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: data.textContentInfo.edges[0].node.summary }} />
            <div
              dangerouslySetInnerHTML={{ __html: data.textContentInfo.edges[0].node.description }}
            />
          </div>
        )}
      </>
    );

    const isDesktopRoom = (
      <>
        <Freya data={data.freyaData && data.freyaData} />
      </>
    );

    const isMobileRoom = (
      <>
        <Freya data={data.freyaData && prepareRoom(data.freyaData)} />
      </>
    );
    return (
      <div className="tab-container-big">
        <MediaServerRender predicted="desktop" hydrated>
          <MediaMatcher mobile={isMobileTrue} desktop={isBrowserTrue} />
        </MediaServerRender>
        {data.type === 'INFO' && (
          <TabContainer>
            <div className="info-tab-cont">
              <Thor data={data.thorData} />
              {/* {currentHotel === 'colorado-creek' ||
              currentHotel === 'gold-river' ||
              currentHotel === 'port-aventura' ? (
                <BannerImgTextButton locale={this.props.locale} />
              ) : null} */}
              <H2 data={data.tyrData} />
              <MediaServerRender predicted="desktop" hydrated>
                <MediaMatcher mobile={isMobile} desktop={isDesktop} />
              </MediaServerRender>
              <H3 data={data.lokiData} />
              <Loki data={data.lokiData} />
              <Gerd pageContext={this.props.pageContext} modal={this.state.showModal} />
              <Ymir data={data.ymirData} />
              <Hellheim data={data.hellheimlData} />
            </div>
          </TabContainer>
        )}
        {data.type === 'EXCLUSIVE' && (
          <TabContainer>
            <div className="exclusive-tab-cont">
              <H2 data={data} />
              <Thor data={data.thorData} />
              <div className="inner-container">
                <H3 data={data.titlere} />
                <div
                  className="text-inner"
                  dangerouslySetInnerHTML={{ __html: data.titlere.text }}
                />
              </div>
              <H3 data={data.lokiData} />
              <Loki data={data.lokiData} />
              <Gerd pageContext={this.props.pageContext} modal={this.state.showModal} />
              <Ymir data={data.ymirData} />
            </div>
          </TabContainer>
        )}
        {data.type === 'ROOMS' && (
          <TabContainer>
            <div className="rooms-tab-cont">
              <H2 data={data} />
              <MediaServerRender predicted="desktop" hydrated>
                <MediaMatcher mobile={isMobileRoom} desktop={isDesktopRoom} />
              </MediaServerRender>
              <Gerd pageContext={this.props.pageContext} modal={this.state.showModal} />
              <Ymir data={data.ymirData} />
            </div>
          </TabContainer>
        )}
        {data.type === 'SERVICES' && (
          <TabContainer>
            <div className="services-tab-cont">
              <H2 data={data} />
              <Loki data={data.lokiData} />
              <div className="services-list-container">
                <H3 data={data.fenrirData} />
                <Fenrir data={data.fenrirData} />
                {this.props.pageContext.hotel_name === 'el-paso' && (
                  <>
                    <div className="services-list-ampliation">
                      <div dangerouslySetInnerHTML={{ __html: data.hardcoded.first_paragraph }} />
                      <div dangerouslySetInnerHTML={{ __html: data.hardcoded.second_paragraph }} />
                      <div dangerouslySetInnerHTML={{ __html: data.hardcoded.third_paragraph }} />
                    </div>
                    <Buttons
                      link={data.hardcoded_button.link_path}
                      size="alone"
                      color="orange"
                      text={data.hardcoded_button.link_copy}
                      blank
                    />
                  </>
                )}
              </div>
              <div className="paw-container">
                <H3 data={data.pawPanelData.header} />
                <PawPanel data={data.pawPanelData.cards} />
                {this.props.pageContext.hotel_name === 'port-aventura' && (
                  <div className="special-services">
                    <H3 data={data.pawPanelData.exceptionTitlePA[0].node} />
                    <PawPanel data={data.pawPanelData.exceptionPA} />
                  </div>
                )}
              </div>
              <Gerd pageContext={this.props.pageContext} modal={this.state.showModal} />
              <Ymir data={data.ymirData} />
            </div>
          </TabContainer>
        )}
        {data.type === 'RESTAURANT' && (
          <TabContainer>
            <div className="restaurants-tab-cont">
              {currentHotel === 'colorado-creek' ||
              currentHotel === 'gold-river' ||
              currentHotel === 'port-aventura' ? (
                <BannerImgTextButton locale={this.props.locale} />
              ) : null}
              <H2 data={data} />
              <Asgard
                locale={locale}
                filterStatusTipoServicios={this.props.filterStatusTipoServicios}
                filterStatusTipoMenu={this.props.filterStatusTipoMenu}
                resetFilter={this.props.resetFilter}
                filterChange={this.props.filterChange}
                data={data.AsgardData}
              />
              <Freya data={data.freyaData} />
              <Gerd pageContext={this.props.pageContext} modal={this.state.showModal} />
              <Ymir data={data.ymirData} />
            </div>
          </TabContainer>
        )}
        {data.type === 'GASTRO' && (
          <TabContainer>
            <div className="exclusive-tab-cont">
              <div className="gastro-title">
                <H2 data={data} />
              </div>
              <div className="gastro-container">
                <Banner data={data.bannerData} />
                <div
                  className="gastro-video"
                  dangerouslySetInnerHTML={{ __html: data.videoData }}
                />
                <div
                  className="table-content-data"
                  dangerouslySetInnerHTML={{ __html: data.contentData }}
                />
              </div>
              <Loki data={data.lokiData} />
            </div>
          </TabContainer>
        )}
        {data.type === 'ARRIVE' && (
          <TabContainer>
            <div className="hta-tab-cont">
              <H2 data={data} />
              <Odin data={data.odinData} locale={locale} />
              <Gerd pageContext={this.props.pageContext} modal={this.state.showModal} />
              <Ymir data={data.ymirData} />
            </div>
          </TabContainer>
        )}
      </div>
    );
  }
}

HotelsTab.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HotelsTab);
