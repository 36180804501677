import React from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import LayoutHotel from '../src/components/LayoutHotels/Main';
import HotelTab from '../src/components/Tabs/Hotels/HotelTab';
import Layout from '../src/components/Layout/Layout';
import Hod from '../src/components/Hod/Hod';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import { generatedBreadCrumb } from '../src/helpers/breadCrumbs';
import SEO from '../src/helpers/seo';
import buildFunnelUrl from '../src/helpers/buildFunnelUrl';
import tt from '../src/helpers/translation';

import './ficha_hotel.scss';

const findDestination = require('../src/helpers/findDestination');
const urls = require('../src/constants/urls');

class HotelsDetails extends React.Component {
  state = {
    loading: false,
    sendInfo: {},
    tipoServicio: '',
    tipoMenu: '',
    initialFilterStateTipoServicio: true,
    initialFilterStateTipoMenu: true,
    showModal: false,
  };

  componentDidMount() {
    const data = {};

    if (this.props.pageContext.tab_name === '') {
      data.GeneralInfo = {
        type: 'INFO',
        thorData: this.prepareForThor(),
        tyrData: {
          title: this.props.data.allHeaderBlockInfoGeneral.edges[0].node.title,
          cards: this.props.data.allRoomInfoGeneralSubBlock.edges.map((block) => ({
            MainImage: block.node.localImage.childImageSharp.fluid,
            Image: block.node.imageIcon,
            altImage: block.node.image_alt,
            Title: block.node.title,
            TitleURL: block.node.button1.cta,
            Text: block.node.description,
            button1: {
              cta: block.node.button1.cta,
              size: block.node.button1.size,
              color: block.node.button1.color,
              ctaText: block.node.button1.ctaText,
            },
            button2: {
              cta: `${block.node.button2.cta}?_ga=2.76409685.1700126798.1640597527-1648847946.1639043608`,
              size: block.node.button2.size,
              color: block.node.button2.color,
              ctaText: block.node.button2.ctaText,
            },
          })),
        },
        lokiData: {
          title: tt('GALERIA DE FOTOS', this.props.pageContext.locale),
          arrayImg: this.props.data.allSliderInfoGeneralSubBlock.edges
            .map((block) => ({
              img: block.node.localImage ? block.node.localImage.childImageSharp.fluid : null,
              altImage: block.node.image_alt,
              position: block.node.position,
            }))
            .filter((item) => item.img !== null),
          bigArrayImg: this.props.data.bigLokiPics.edges
            .map((block) => ({
              img: block.node.localImage ? block.node.localImage.childImageSharp.fluid : null,
              altImage: block.node.image_alt,
              position: block.node.position,
            }))
            .filter((item) => item.img !== null),
          video: this.props.data.allVideoHotel.edges.filter((item) => item.node.video !== ''),
          // video: this.props.data.allSliderInfoGeneralSubBlock.edges.filter(item => item.node.video !== 'undefined')[0] ? this.props.data.allSliderInfoGeneralSubBlock.edges.filter(item => item.node.video !== 'undefined')[0].node.video : false,
        },

        ymirData: {
          cards: this.prepareForYmir(),
        },
        hellheimlData: {
          title: this.props.data.allFaqBlock.edges[0].node.title,
          text: '',
          menus: this.props.data.allFaqBlockSub.edges.map((item, index) => {
            return {
              title: item.node.question,
              text: item.node.answer,
            };
          }),
        },
        textContentInfo: this.props.data.allTextContentInfoGeneral,
      };
      this.setState({ sendInfo: data.GeneralInfo });
    } else if (this.props.pageContext.tab_name === 'beneficios-exclusivos') {
      data.ExclusiveData = {
        type: 'EXCLUSIVE',
        title: this.props.data.allHeaderBlockInfoGeneral.edges[0].node.title,
        titlere: {
          title: this.props.data.allSummaryBeneficiosBlock.edges[0].node.title,
          text: this.props.data.allSummaryBeneficiosBlock.edges[0].node.summary,
        },
        description: this.props.data.allHeaderBlockInfoGeneral.edges[0].node.subTitle,
        thorData: this.prepareForThor2(),
        lokiData: {
          title: tt('GALERIA DE FOTOS', this.props.pageContext.locale),
          arrayImg: this.props.data.allSliderInfoGeneralSubBlock.edges
            .map((block) => ({
              img: block.node.localImage ? block.node.localImage.childImageSharp.fluid : null,
              altImage: block.node.image_alt,
            }))
            .filter((item) => item.img !== null),
          bigArrayImg: this.props.data.bigLokiPics.edges
            .map((block) => ({
              img: block.node.localImage ? block.node.localImage.childImageSharp.fluid : null,
              altImage: block.node.image_alt,
            }))
            .filter((item) => item.img !== null),
        },
        ymirData: {
          cards: this.prepareForYmir(),
        },
      };
      this.setState({ sendInfo: data.ExclusiveData });
    } else if (this.props.pageContext.tab_name === 'habitaciones') {
      data.RoomsData = {
        type: 'ROOMS',
        title: this.props.data.allHeaderBlockInfoGeneral.edges[0].node.title,
        description: this.props.data.allHeaderBlockInfoGeneral.edges[0].node.subTitle,
        ymirData: {
          cards: this.prepareForYmir(),
        },
        freyaData: {
          title: tt('HABITACIONES', this.props.pageContext.locale),
          cards: this.props.data.allListRestaurantsSubBlock.edges.map((item) => ({
            image: item.node.localImage.childImageSharp.fluid,
            altImage: item.node.alt,
            fenrirData: {
              list: item.node.characteristics,
            },
            title: item.node.title,
            description: item.node.description,
            imgsize: 'large',
            seeMore: '/entradas',
            located: item.node.location,
            twoButton: true,
            buttons: {
              cta2: item.node.call_to_action_path,
              size2: 'small',
              color2: 'orange',
              ctaText2: tt('RESERVAR AHORA', this.props.pageContext.locale),
              cta: item.node.see_more_path,
              size: 'small',
              color: 'white',
              ctaText: tt('DESCUBRIR', this.props.pageContext.locale),
            },
          })),
        },
      };
      this.setState({ sendInfo: data.RoomsData });
    } else if (this.props.pageContext.tab_name === 'servicios') {
      this.props.data.allListServiciosAdicionalesSubBlock.edges.length = 14;
      data.ServicesData = {
        type: 'SERVICES',
        title: this.props.data.allHeaderBlockInfoGeneral.edges[0].node.title,
        description: this.props.data.allHeaderBlockInfoGeneral.edges[0].node.subTitle,
        lokiData: {
          title: tt('GALERIA DE FOTOS', this.props.pageContext.locale),
          arrayImg: this.props.data.allSliderInfoGeneralSubBlock.edges
            .map((block) => ({
              img: block.node.localImage ? block.node.localImage.childImageSharp.fluid : null,
              altImage: block.node.image_alt,
            }))
            .filter((item) => item.img !== null),
          bigArrayImg: this.props.data.bigLokiPics.edges
            .map((block) => ({
              img: block.node.localImage ? block.node.localImage.childImageSharp.fluid : null,
              altImage: block.node.image_alt,
            }))
            .filter((item) => item.img !== null),
        },
        hardcoded:
          this.props.pageContext.hotel_name === 'el-paso'
            ? this.props.data.allHotelDetailsBigParagraph.edges[0].node
            : null,
        hardcoded_button:
          this.props.pageContext.hotel_name === 'el-paso'
            ? this.props.data.allHotelDetailsBigParagraphButton.edges[0].node
            : null,
        ymirData: {
          cards: this.prepareForYmir(),
        },
        fenrirData: {
          list:
            this.props.data.allListBeneficiosExclusivosSubBlock.edges[0].node.body_left +
            this.props.data.allListBeneficiosExclusivosSubBlock.edges[0].node.body_right,
          title: tt('SERVICIOS', this.props.pageContext.locale),
          listTitle: tt('Servicios', this.props.pageContext.locale),
        },
        pawPanelData: {
          header: {
            title: tt('SERVICIOS ADICIONALES', this.props.pageContext.locale),
          },
          cards: this.props.data.allListServiciosAdicionalesSubBlock.edges,
          exceptionTitlePA: this.props.data.allListPortaventuraServicesTitle.edges,
          exceptionPA: this.props.data.allListPortaventuraServices.edges,
        },
      };
      this.setState({ sendInfo: data.ServicesData });
    } else if (this.props.pageContext.tab_name === 'restaurantes') {
      data.RestaurantData = {
        type: 'RESTAURANT',
        title: this.props.data.allHeaderBlockInfoGeneral.edges[0].node.title,
        description: this.props.data.allHeaderBlockInfoGeneral.edges[0].node.subTitle,
        AsgardData: {
          image:
            this.props.data.allHotelIconBlock.edges.length &&
            this.props.data.allHotelIconBlock.edges[0].node.Image,
          text: `${this.props.data.allListRestaurantsSubBlock.edges.length} ${tt(
            'RESTAURANTES',
            this.props.pageContext.locale
          )}`,
          filters: [
            {
              tag: tt('TIPO DE SERVICIO', this.props.pageContext.locale),
              options: [
                { value: tt('Autoservicio', this.props.pageContext.locale), label: 'self_serive' },
                {
                  value: tt('Servicio a mesa', this.props.pageContext.locale),
                  label: 'table_service',
                },
                { value: tt('On the go', this.props.pageContext.locale), label: 'on_the_go' },
              ],
            },
            {
              tag: tt('TIPO DE MENÚ', this.props.pageContext.locale),
              options: [
                {
                  value: tt('Comida para celiacos', this.props.pageContext.locale),
                  label: 'celiac_food',
                },
                {
                  value: tt('Menú infantil', this.props.pageContext.locale),
                  label: 'children_menu',
                },
                { value: tt('Menú express', this.props.pageContext.locale), label: 'express_menu' },
              ],
            },
          ],
        },
        ymirData: {
          cards: this.prepareForYmir(),
        },
        freyaData: {
          title: tt('PROMOCIONES RELACIONADAS', this.props.pageContext.locale),
          cards: this.props.data.allListRestaurantsSubBlock.edges.map((item) => ({
            image: item.node.localImage.childImageSharp.fluid,
            altImage: item.node.alt,
            fenrirData: {
              list: item.node.characteristics,
            },
            title: item.node.title,
            titleType: 'h3',
            description: item.node.description,
            imgsize: 'large',
            seeMore: tt('/entradas', this.props.pageContext.locale),
            located: item.node.location,
            features: item.node.features,
            buttons: {
              cta: item.node.see_more_path,
              size: 'alone',
              color: 'white',
              ctaText: tt('DESCUBRIR', this.props.pageContext.locale),
            },
          })),
        },
      };
      this.setState({ sendInfo: data.RestaurantData });
    } else if (this.props.pageContext.tab_name === 'delion-experience') {
      data.GastroData = {
        type: 'GASTRO',
        title: this.props.data.allHeaderBlockInfoGeneral.edges[0].node.title,
        titlere: {
          title: this.props.data.allSummaryBeneficiosBlock.edges[0].node.title,
          text: this.props.data.allSummaryBeneficiosBlock.edges[0].node.summary,
        },
        // description: this.props.data.allHeaderBlockInfoGeneral.edges[0].node.subTitle,
        lokiData: {
          title: tt('GALERIA DE FOTOS', this.props.pageContext.locale),
          arrayImg: this.props.data.allSliderGastroSubBlock.edges
            .map((block) => ({
              img: block.node.localImage ? block.node.localImage.childImageSharp.fluid : null,
              altImage: block.node.image_alt,
            }))
            .filter((item) => item.img !== null),
          bigArrayImg: this.props.data.bigGastroLokiPics.edges
            .map((block) => ({
              img: block.node.localImage ? block.node.localImage.childImageSharp.fluid : null,
              altImage: block.node.image_alt,
            }))
            .filter((item) => item.img !== null),
        },
        videoData: this.props.data.allGastroVideoBlock.edges[0].node.video,
        bannerData: {
          imgDesktop: this.props.data.allGastroBannerBlock.edges[0].node.localImage.childImageSharp,
          imgMobile: this.props.data.allGastroBannerBlock.edges[0].node.localImage1.childImageSharp,
        },
        contentData: this.props.data.allGastroContentBlock.edges[0].node.text,
      };
      this.setState({ sendInfo: data.GastroData });
    } else if (this.props.pageContext.tab_name === 'situacion') {
      data.comoLlegar = {
        type: 'ARRIVE',
        title: this.props.data.allHeaderBlockInfoGeneral.edges[0].node.title,
        description: this.props.data.allHeaderBlockInfoGeneral.edges[0].node.subTitle,
        odinData: {
          image: this.props.data.howToArriveBlock.localImage.childImageSharp.fluid,
          alt: this.props.data.howToArriveBlock.image_alt,
          title: this.props.data.allSituacionDirectionsBlock.edges[0].node.title,
          description: this.props.data.allSituacionDirectionsBlock.edges[0].node.description,
          address: this.props.data.allSituacionDirectionsBlock.edges[0].node.address,
          buttons: {
            cta1: '/destino/planificar/como-llegar-a-portaventura',
            ctaText1: tt('MÁS INFO', this.props.pageContext.locale),
            size: 'small',
            color1: 'orange',
          },
        },
        ymirData: {
          cards: this.prepareForYmir(),
        },
      };
      this.setState({ sendInfo: data.comoLlegar });
    }
  }

  handleMap = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  prepareForYmir = () => {
    const dc = this.props.data.allAdditionalLinks.edges[0].node.cards;
    let scheduleType;
    switch (this.props.pageContext.locale) {
      case 'es':
        scheduleType = 'horarios-calendario';
        break;
      case 'fr':
        scheduleType = 'horaires-calendrier';
        break;
      case 'ca':
        scheduleType = 'horaris-calendari';
        break;
      case 'en':
        scheduleType = 'dates-times';
        break;
      case 'de':
        scheduleType = 'oeffnungszeiten-kalender';
        break;
      case 'it':
        scheduleType = 'orari-calendari';
        break;
      case 'ru':
        scheduleType =
          '%D1%80%D0%B0%D1%81%D0%BF%D0%B8%D1%81%D0%B0%D0%BD%D0%B8%D0%B5-%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D0%BA';
        break;
      default:
        scheduleType = 'horarios-calendario';
    }
    const ret2 = [
      {
        title: dc._0.title,
        buttonCard: dc._0.buttonCard,
        link: `${this.props.pageContext.options[0]}/${scheduleType}`,
        icon: dc._0.icon,
      },
      {
        title: dc._1.title,
        buttonCard: dc._1.buttonCard,
        link: dc._1.link,
        icon: dc._1.icon,
      },
      {
        title: dc._2.title,
        buttonCard: dc._2.buttonCard,
        link: dc._2.link,
        icon: dc._2.icon,
      },
    ];
    return ret2;
  };

  prepareForThor = () => {
    const dn = this.props.data.allListBeneficiosInfoGeneral.edges[0].node;
    let ret;

    if (
      this.props.pageContext.hotel_name === 'colorado-creek' ||
      this.props.pageContext.hotel_name === 'gold-river' ||
      this.props.pageContext.hotel_name === 'port-aventura'
    ) {
      ret = {
        title: dn.title,
        subtitle: dn.subtitle,
        cards: [
          {
            Icon: dn.cards._0.Icon,
            titleIcon: dn.cards._0.titleIcon,
            subtitleIcon: dn.cards._0.subtitleIcon,
          },
          {
            Icon: dn.cards._1.Icon,
            titleIcon: dn.cards._1.titleIcon,
            subtitleIcon: dn.cards._1.subtitleIcon,
          },
          {
            Icon: dn.cards._2.Icon,
            titleIcon: dn.cards._2.titleIcon,
            subtitleIcon: dn.cards._2.subtitleIcon,
          },
          {
            Icon: dn.cards._3.Icon,
            titleIcon: dn.cards._3.titleIcon,
            subtitleIcon: dn.cards._3.subtitleIcon,
          },
        ],
      };
    } else {
      ret = {
        title: dn.title,
        subtitle: dn.subtitle,
        cards: [
          {
            Icon: dn.cards._0.Icon,
            titleIcon: dn.cards._0.titleIcon,
            subtitleIcon: dn.cards._0.subtitleIcon,
          },
          {
            Icon: dn.cards._1.Icon,
            titleIcon: dn.cards._1.titleIcon,
            subtitleIcon: dn.cards._1.subtitleIcon,
          },
          {
            Icon: dn.cards._2.Icon,
            titleIcon: dn.cards._2.titleIcon,
            subtitleIcon: dn.cards._2.subtitleIcon,
          },
        ],
      };
    }
    return ret;
  };

  prepareForThor2 = () => {
    const ret = {
      title: this.props.data.allListBeneficiosInfoGeneral.edges[0].node.title,
      subtitle: this.props.data.allListBeneficiosInfoGeneral.edges[0].node.subtitle,
      cards: [],
    };
    this.props.data.allExclusiveBenefitsListSubBlock.edges.map((e) => {
      if (
        this.props.pageContext.hotel_name === 'mansion-de-lucy' ||
        this.props.pageContext.hotel_name === 'caribe' ||
        this.props.pageContext.hotel_name === 'el-paso'
      ) {
        if (e.node.icon_css_class !== 'delion') {
          ret.cards.push({
            Icon: e.node.icon_css_class,
            titleIcon: e.node.title,
            subtitleIcon: e.node.summary,
          });
        }
      } else {
        ret.cards.push({
          Icon: e.node.icon_css_class,
          titleIcon: e.node.title,
          subtitleIcon: e.node.summary,
        });
      }
    });
    return ret;
  };

  handleFilter = (name, tag) => {
    if (tag === 'TIPO DE SERVICIO')
      this.setState({ tipoServicio: name, initialFilterStateTipoServicio: false }, () =>
        this.manageFilter()
      );
    else if (tag === 'TIPO DE MENÚ')
      this.setState({ tipoMenu: name, initialFilterStateTipoMenu: false }, () =>
        this.manageFilter()
      );
  };

  manageFilter = () => {
    const originalFreya = {
      title: tt('PROMOCIONES RELACIONADAS', this.props.pageContext.locale),
      cards: this.props.data.allListRestaurantsSubBlock.edges.map((item) => ({
        image: item.node.localImage.childImageSharp.fluid,
        altImage: item.node.alt,
        fenrirData: {
          list: item.node.characteristics,
        },
        title: item.node.title,
        description: item.node.description,
        imgsize: 'large',
        seeMore: tt('/entradas', this.props.pageContext.locale),
        located: item.node.location,
        features: item.node.features,
        buttons: {
          cta: item.node.see_more_path,
          size: 'alone',
          color: 'white',
          ctaText: tt('RESERVAR', this.props.pageContext.locale),
        },
      })),
    };
    const {
      tipoMenu,
      tipoServicio,
      initialFilterStateTipoServicio,
      initialFilterStateTipoMenu,
      sendInfo,
    } = this.state;
    const newRestaurants = [...originalFreya.cards].filter((item) => {
      if (initialFilterStateTipoServicio === true && initialFilterStateTipoMenu) return item;
      if (tipoServicio !== '' && tipoMenu === '') {
        return item.features.includes(tipoServicio.toLowerCase());
      }
      if (tipoServicio === '' && tipoMenu !== '') {
        return item.features.includes(tipoMenu.toLowerCase());
      }
      if (tipoServicio !== '' && tipoMenu !== '') {
        return (
          item.features.includes(tipoServicio.toLowerCase()) &&
          item.features.includes(tipoMenu.toLowerCase())
        );
      }
    });
    const newSendInfo = { ...sendInfo };
    newSendInfo.freyaData.cards = newRestaurants;
    this.setState({ newSendInfo });
  };

  resetFilter = () => {
    this.setState(
      {
        initialFilterStateTipoServicio: true,
        initialFilterStateTipoMenu: true,
        tipoServicio: '',
        tipoMenu: '',
      },
      () => this.manageFilter()
    );
  };

  tabNameResolver = () => {
    const tab = this.props.pageContext.tab_name;
    const lang = this.props.pageContext.locale;
    switch (tab) {
      case '':
        return tt('Información general', lang);
      case 'beneficios-exclusivos':
        return tt('Beneficios exclusivos', lang);
      case 'servicios':
        return tt('Servicios', lang);
      case 'restaurantes':
        return tt('Restaurantes', lang);
      case 'delion-experience':
        return tt('Delion Experience', lang);
      case 'habitaciones':
        return tt('Habitaciones', lang);
      case 'situacion':
        return tt('Cómo llegar', lang);
      default:
        return tt('Información general', lang);
    }
  };

  render() {
    this.props.data.allHeaderBlockInfoGeneral.edges[0].node.subTitle2 =
      this.props.data.subtitleHeaderBlock.edges[0].node.subTitle;
    const { sendInfo, hotelData } = this.state;
    // const aesirData = {
    //   breadcrumbs: generatedBreadCrumb(
    //     this.props.data.allFichaHotelesBreadCrumbBlock.edges[0].node.destination_url,
    //     this.props.data.allFichaHotelesBreadCrumbBlock.edges[0].node.name
    //   ),
    //   pawURL: tt('/', this.props.pageContext.locale),
    // };

    const handleDataChange = (type) => {
      const { hotel_name, tab_name } = this.props.pageContext;
      const lang = this.props.pageContext.locale;
      const validOptions = {
        hotelname: urls.templates_multilang.ficha_hoteles.options.hotelname[lang],
        section: urls.templates_multilang.ficha_hoteles.options.section[lang],
      };
      let section = '';

      const sectionBase = urls.templates_multilang.ficha_hoteles.options.section[lang];
      switch (type) {
        case 'INFO':
          section = sectionBase[0];
          break;
        case 'EXCLUSIVE':
          section = sectionBase[1];
          break;
        case 'ROOMS':
          section = sectionBase[2];
          break;
        case 'RESTAURANT':
          section = sectionBase[3];
          break;
        case 'GASTRO':
          section = sectionBase[6];
          break;
        case 'SERVICES':
          section = sectionBase[4];
          break;
        case 'ARRIVE':
          section = sectionBase[5];
          break;
      }
      const localizedUrl = urls.templates_multilang.ficha_hoteles.urls.filter(
        (a) => a.lang === lang
      )[0];
      const dest = findDestination.findDestination(localizedUrl.urlGatsby, validOptions, [
        hotel_name,
        section,
      ]);

      navigate(dest);
    };

    const heimdallData = {
      name: this.props.data.allHeaderBlockInfoGeneral.edges[0].node.title,
      image: {
        url: this.props.data.allHeaderBlockImageInfoGeneral.edges[0].node.localImage.childImageSharp
          .fluid,
        alt: this.props.data.allHeaderBlockImageInfoGeneral.edges[0].node.alt,
      },
      imageMobile: {
        url: this.props.data.hotelDetailsMobileImage.localImage.childImageSharp.fluid,
      },
      starsHotel: this.props.data.allHeaderBlockInfoGeneral.edges[0].node.stars,
      starsColor: 'white',
      map: 'true',
    };
    const button1 = {
      cta: tt('/hoteles/check-in-online', this.props.pageContext.locale),
      size: 'small',
      color: 'white',
      ctaText: tt('CHECKIN ONLINE', this.props.pageContext.locale),
    };

    const button2 = {
      cta: `${buildFunnelUrl(
        this.props.pageContext.locale,
        'hotels'
      )}?_ga=2.76409685.1700126798.1640597527-1648847946.1639043608`,
      size: 'small',
      color: 'orange',
      ctaText: tt('RESERVAR AHORA', this.props.pageContext.locale),
    };
    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
        options={this.props.pageContext.options}
      >
        <SEO
          title={this.props.data.allHotelDetailsSeoData.edges[0].node._3.title}
          description={this.props.data.allHotelDetailsSeoData.edges[0].node._4.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <LayoutHotel
          pageContext={this.props.pageContext}
          urlCTA={
            this.props.data.allHotelIconBlock.edges.length &&
            this.props.data.allHotelIconBlock.edges[0].node.ctaURL
          }
          button2={button2}
          button1={this.props.pageContext.hotel_name === 'mansion-de-lucy' ? undefined : button1}
          heimdallData={heimdallData}
          handleMap={this.handleMap}
          states={this.state.showModal}
          promos={
            this.props.data.allPromoInfoGeneralSubBlock &&
            this.props.data.allPromoInfoGeneralSubBlock.edges
          }
          layoutImage={
            this.props.data.allHeaderBlockImageInfoGeneral.edges[0].node.localImage.childImageSharp
              .fluid
          }
          icon={
            this.props.data.allHotelIconBlock.edges.length &&
            this.props.data.allHotelIconBlock.edges[0].node.Image
          }
          layoutData={this.props.data.allHeaderBlockInfoGeneral.edges[0].node}
        >
          <div className="hotel-details-container">
            <BlueArrow />
            {/* <NeoAesir
              data={aesirData}
              locale={this.props.pageContext.locale}
              pageName={this.props.pageContext.pageName}
              options={this.props.pageContext.options}
              title={this.tabNameResolver()}
            /> */}
            <Hod
              hardcoded={
                this.props.data.allHotelDetailsRich.edges.length &&
                this.props.data.allHotelDetailsRich.edges[0].node.richmark
              }
              data={this.props.data.allFichaHotelesRichData.edges[0].node.rich_markup}
              url={this.props.pageContext.url}
            />
            <HotelTab
              pageContext={this.props.pageContext}
              filterStatusTipoServicios={this.state.initialFilterStateTipoServicio}
              filterStatusTipoMenu={this.state.initialFilterStateTipoMenu}
              resetFilter={this.resetFilter}
              filterChange={this.handleFilter}
              currentHotel={this.props.pageContext.hotel_name}
              data={this.state.sendInfo}
              locale={this.props.pageContext.locale}
              onChangeData={handleDataChange}
            />
          </div>
        </LayoutHotel>
      </Layout>
    );
  }
}

/* eslint-disable react/require-default-props */
HotelsDetails.propTypes = {
  HotelsDetailsData: PropTypes.shape({}),
};
/* eslint-enable react/require-default-props */

export default HotelsDetails;

export const pageQuery = graphql`
  query allFichaHotelQuery($hotel_name: String!, $tab_name: String!, $locale: String!) {
    allHeaderBlockInfoGeneral(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          hotel
          tab
          link
          colorRound
          title
          subTitle
          stars
          button1 {
            cta
            size
            color
            ctaText
          }
          button2 {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allListPortaventuraServices(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          description
        }
      }
    }
    allListPortaventuraServicesTitle(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
        }
      }
    }
    subtitleHeaderBlock: allHeaderBlockInfoGeneral(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: "" }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          subTitle
        }
      }
    }
    allVideoHotel(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          video
        }
      }
    }
    allHeaderBlockImageInfoGeneral(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          alt
          hotel
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    hotelDetailsMobileImage(hotel: { eq: $hotel_name }, lang: { eq: $locale }) {
      localImage {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    allListBeneficiosInfoGeneral(
      filter: { hotel: { eq: $hotel_name }, lang: { eq: $locale }, tab: { eq: $tab_name } }
    ) {
      edges {
        node {
          hotel
          tab
          title
          subtitle
          cards {
            _0 {
              Icon
              titleIcon
              subtitleIcon
            }
            _1 {
              Icon
              titleIcon
              subtitleIcon
            }
            _2 {
              Icon
              titleIcon
              subtitleIcon
            }
            _3 {
              Icon
              titleIcon
              subtitleIcon
            }
          }
        }
      }
    }
    allHotelDetailsSeoData(
      filter: { _1: { hotel: { eq: $hotel_name } }, _0: { tab: { eq: $tab_name } } }
    ) {
      edges {
        node {
          _0 {
            tab
          }
          _1 {
            hotel
          }
          _2 {
            tag
          }
          _3 {
            title
          }
          _4 {
            description
          }
        }
      }
    }
    allRoomInfoGeneralSubBlock(
      filter: { hotel: { eq: $hotel_name }, lang: { eq: $locale }, tab: { eq: $tab_name } }
    ) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          imageIcon
          type
          title
          description
          mainText
          secondaryText
          button1 {
            cta
            size
            color
            ctaText
          }
          button2 {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allHotelDetailsRich(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          richmark
        }
      }
    }
    allTextContentInfoGeneral(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          title
          summary
          description
        }
      }
    }
    allSliderInfoGeneralSubBlock(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          hotel
          tab
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          position
        }
      }
    }
    bigLokiPics: allSliderInfoGeneralSubBlock(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          position
        }
      }
    }
    allSliderGastroSubBlock(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          hotel
          tab
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          position
        }
      }
    }
    bigGastroLokiPics: allSliderGastroSubBlock(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          position
        }
      }
    }
    allGastroVideoBlock(
      filter: {
        hotel: { eq: $hotel_name }
        tab: { eq: $tab_name }
        lang: { eq: $locale }
        type: { eq: "VIDEO DELION" }
      }
    ) {
      edges {
        node {
          video
          type
        }
      }
    }
    allGastroContentBlock(
      filter: {
        hotel: { eq: $hotel_name }
        tab: { eq: $tab_name }
        lang: { eq: $locale }
        type: { eq: "PARRAFO" }
      }
    ) {
      edges {
        node {
          text
          type
        }
      }
    }
    allGastroBannerBlock(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imageMobile
          localImage1 {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allAdditionalLinks(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          id
          cards {
            _0 {
              title
              buttonCard
              link
              icon
            }
            _1 {
              title
              buttonCard
              link
              icon
            }
            _2 {
              title
              buttonCard
              link
              icon
            }
          }
        }
      }
    }
    allFaqBlockSub(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          id
          question
          answer
        }
      }
    }
    allHotelDetailsBigParagraph(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          first_paragraph
          second_paragraph
          third_paragraph
        }
      }
    }
    allHotelDetailsBigParagraphButton(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          link_copy
          link_path
        }
      }
    }
    allFaqBlock(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          id
          title
        }
      }
    }
    allPromoInfoGeneralSubBlock(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          id
          title
          description
          localImage {
            childImageSharp {
              fluid(maxWidth: 300, maxHeight: 300, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
          imgsize
          stars
          buttons {
            cta
            size
            color
            ctaText
          }
          description
        }
      }
    }
    allSummaryBeneficiosBlock(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          hotel
          tab
          summary
          title
        }
      }
    }
    allSummaryGastroBlock(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          hotel
          tab
          summary
          title
        }
      }
    }
    allExclusiveBenefitsListSubBlock(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          tab
          hotel
          icon_css_class
          title
          summary
        }
      }
    }
    allListRestaurantsSubBlock(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          tab
          description
          characteristics
          call_to_action_copy
          call_to_action_path
          see_more_copy
          see_more_path
          title
          features
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
        }
      }
    }
    allListServiciosAdicionalesSubBlock(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          tab
          title
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          altImage
          description
        }
      }
    }
    allListBeneficiosExclusivosSubBlock(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          tab
          title
          body_left
          body_right
        }
      }
    }
    allInfoServiciosBlock {
      edges {
        node {
          title
          subtitle
        }
      }
    }
    allMoreInfoAdditionalServices {
      edges {
        node {
          title
          subtitle
        }
      }
    }
    allSituacionDirectionsBlock(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          title
          address
          description
          call_to_action_path
          call_to_action_copy
          stars
        }
      }
    }
    allAlternativeSliderGalleryBlock(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          title
          description
        }
      }
    }
    allHotelIconBlock(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          Image
          ctaURL
        }
      }
    }
    howToArriveBlock {
      localImage {
        childImageSharp {
          fluid(maxWidth: 700, maxHeight: 380, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image_alt
    }
    allFichaHotelesBreadCrumbBlock(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          tag
          destination_url
          name
        }
      }
    }
    allFichaHotelesRichData(
      filter: { extra: { eq: $hotel_name }, extra2: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          rich_markup {
            color_css_class
            og_description
            og_title
            twitter_description
            twitter_title
            created_at
            updated_at
            rich_markup_type
          }
        }
      }
    }
  }
`;
